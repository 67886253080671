import {createSelector} from "reselect";
import {createAction, createReducer} from "typesafe-actions";

import * as api from "../api";
import {Access, Lecture} from "../types";
import type {AsyncThunkAction, State as RootState} from "./index";
import {getUser, selectUser} from "./user";
import {createSubReducer} from "./util";

export type State = {
	total: Record<Lecture["id"], number>;
	access: Record<Access["id"], Access>;
};

const initial: State = {
	total: {},
	access: {},
};

export const selector = (state: RootState): State => state.access;
export const selectTotalMap = createSelector(selector, (state) => state.total);
export const selectTotal = (id: Lecture["id"]) => (state: RootState) => selectTotalMap(state)[id];
export const selectAccessMap = createSelector(selector, (state) => state.access);
export const selectAccess = (id: Access["id"]) => (state: RootState) => selectAccessMap(state)[id];

export const setTotal = createAction("ACCESS/TOTAL/SET")<[Lecture["id"], number]>();
export const setAccess = createAction("ACCESS/SET")<Access>();

export type Action =
	| ReturnType<typeof setTotal>
	| ReturnType<typeof setAccess>;
export const reducer = createReducer<State, Action>(initial, {
	"ACCESS/TOTAL/SET": createSubReducer((state, action) => {
		const [id, total] = action.payload;
		state.total[id] = total;
	}),
	"ACCESS/SET": createSubReducer((state, action) => {
		const access = action.payload;
		state.access[access.id] = access;
	}),
});

export function getAccessList(id: number, offset: number, limit: number): AsyncThunkAction<Access[]> {
	return async (dispatch, getState) => {
		const state = getState();

		const response = await api.getLectureAccess(id, offset, limit);
		for (const access of response.data) {
			dispatch(setAccess(access));
			if (selectUser(access.user)(state) == null) {
				await dispatch(getUser(access.user));
			}
		}
		dispatch(setTotal([id, response.total]));

		return response.data;
	};
}
