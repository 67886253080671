import urlcat from "urlcat";
import {fetch} from "whatwg-fetch";

import {Access, Admin, Lecture, Notice, User} from "./types";

let BASE: string;
if (process.env.NODE_ENV === "production") {
	BASE = "https://api.admin.kiaedulive.com";
} else {
	BASE = "http://localhost:8081";
}

export function GET(path: string) {
	return request("GET", path);
}

export function POST(path: string, body: any) {
	return request("POST", path, body);
}

export function PUT(path: string, body: any) {
	return request("PUT", path, body);
}

export function DELETE(path: string) {
	return request("DELETE", path);
}

export function PATCH(path: string, body: any) {
	return request("PATCH", path, body);
}

export class HttpError extends Error {
	public code: number;
	constructor(code: number, message: string) {
		super(message);
		this.code = code;
	}
}

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
export async function request(method: Method, url: string, body?: any) {
	const response = await fetch(url, {
		method,
		headers: { "Content-Type": "application/json" },
		body: body === undefined ? undefined : JSON.stringify(body),
		credentials: "include",
	});
	if (response.status >= 400) {
		throw new HttpError(response.status, "API Error: " + response.status);
	} else if (response.status === 204) {
		return undefined;
	} else {
		const contentType = response.headers.get("Content-Type");
		if (contentType?.includes("application/json")) {
			return response.json();
		} else {
			return response.text();
		}
	}
}

export async function getSession(): Promise<Admin> {
	const url = urlcat(BASE, "session");
	return await GET(url);
}

export async function postSession(email: string, password: string): Promise<Admin> {
	const url = urlcat(BASE, "session");
	return await POST(url, {email, password});
}

export async function deleteSession() {
	const url = urlcat(BASE, "session");
	return await DELETE(url);
}

export async function getUserList(offset: number, limit: number): Promise<{
	total: number;
	data: User[];
}> {
	const url = urlcat(BASE, "user", {limit, offset});
	return await GET(url);
}

export async function getUser(id: number): Promise<User> {
	const url = urlcat(BASE, "user/:id", {id});
	return await GET(url);
}

export async function patchUser(id: number, body: Omit<User, "id" | "email">): Promise<User> {
	const url = urlcat(BASE, "user/:id", {id});
	return await PATCH(url, body);
}

export async function getLectureList(offset: number, limit: number): Promise<{
	total: number;
	data: Lecture[];
}> {
	const url = urlcat(BASE, "lecture", {limit, offset});
	return await GET(url);
}

export async function getLecture(id: number): Promise<Lecture> {
	const url = urlcat(BASE, "lecture/:id", {id});
	return await GET(url);
}

export async function postLecture(body: Omit<Lecture, "id" | "timestamp">): Promise<Lecture> {
	const url = urlcat(BASE, "lecture");
	return await POST(url, body);
}

export async function patchLecture(id: number, body: Omit<Lecture, "id" | "timestamp">): Promise<Lecture> {
	const url = urlcat(BASE, "lecture/:id", {id});
	return await PATCH(url, body);
}

export async function deleteLecture(id: number): Promise<void> {
	const url = urlcat(BASE, "lecture/:id", {id});
	return await DELETE(url);
}

export async function getLectureAccess(id: number, offset: number, limit: number): Promise<{
	total: number;
	data: Access[];
}> {
	const url = urlcat(BASE, "lecture/:id/access", {id, offset, limit});
	return await GET(url);
}

export async function getNoticeList(offset: number, limit: number): Promise<{
	total: number;
	data: Notice[];
}> {
	const url = urlcat(BASE, "notice", {limit, offset});
	return await GET(url);
}

export async function getNotice(id: number): Promise<Notice> {
	const url = urlcat(BASE, "notice/:id", {id});
	return await GET(url);
}

export async function postNotice(body: Omit<Notice, "id" | "timestamp">): Promise<Notice> {
	const url = urlcat(BASE, "notice");
	return await POST(url, body);
}

export async function patchNotice(id: number, body: Omit<Notice, "id" | "timestamp">): Promise<Notice> {
	const url = urlcat(BASE, "notice/:id", {id});
	return await PATCH(url, body);
}

export async function deleteNotice(id: number): Promise<void> {
	const url = urlcat(BASE, "notice/:id", {id});
	return await DELETE(url);
}

export async function getBannerUrl(): Promise<{url: string}> {
	const url = urlcat(BASE, "banner/url");
	return await GET(url);
}

export async function postBannerRefresh(): Promise<void> {
	const url = urlcat(BASE, "banner/refresh");
	return await POST(url, {});
}
