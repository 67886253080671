import React from 'react';

import {getBannerUrl, postBannerRefresh} from "../api";
import './Banner.css';

function Banner() {
	const [imageUid, setImageUid] = React.useState(new Date().valueOf());
	const [isUploading, setIsUploading] = React.useState(false);
	const [file, setFile] = React.useState<File>();
	const [dataUrl, setDataUrl] = React.useState<string>();

	const onChange = React.useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputFile = e.target.files?.[0];
		let inputDataUrl: string | undefined;
		if (inputFile !== undefined) {
			const reader = new FileReader();
			inputDataUrl = await new Promise((res) => {
				reader.addEventListener("load", () => res(reader.result as string));
				reader.readAsDataURL(inputFile);
			});
		}
		setFile(inputFile);
		setDataUrl(inputDataUrl);
	}, []);
	const onConfirm = React.useCallback(async () => {
		if (isUploading || file === undefined) {
			return;
		}
		setIsUploading(true);
		const {url} = await getBannerUrl();
		await fetch(url, {
			method: "PUT",
			credentials: "include",
			body: file,
			headers: {
				"Content-Type": "image/jpeg",
			},
		});
		await postBannerRefresh();
		setIsUploading(false);
		setImageUid(new Date().valueOf());
		setFile(undefined);
		setDataUrl(undefined);
	}, [isUploading, file]);

	const bannerUrl = "https://www.kiaedulive.com/banner.jpg?" + imageUid.toString();

	return (
		<div className="banner-view">
			<div className="section">
				<div className="container">
					<h1 className="title">배너 편집</h1>
					<div className="banner-view-body">
						<div className="banner-view-image">
							<h2>이전</h2>
							<div className="banner-view-image-wrapper">
								<img src={bannerUrl} />
							</div>
						</div>
						<div className="banner-view-image">
							<h2>
								교체
								<label className="file-label">
									<input
										className="file-input"
										type="file"
										accept="image/jpeg"
										onChange={onChange}
									/>
									<span className="file-cta">
										<span className="file-label">파일 선택</span>
									</span>
								</label>
							</h2>
							<div className="banner-view-image-wrapper">
								<img src={dataUrl} />
							</div>
						</div>
					</div>
					<div className="lecture-view-buttons">
						<button
							className={"button" + (isUploading ? " is-loading" : "")}
							onClick={onConfirm}
						>
							적용
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Banner;
