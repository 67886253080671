import {createSelector} from "reselect";
import {createAction, createReducer} from "typesafe-actions";

import * as api from "../api";
import {User} from "../types";
import type {AsyncThunkAction, State as RootState} from "./index";
import {createSubReducer} from "./util";

export type State = {
	total: number;
	user: Record<User["id"], User>;
};

const initial: State = {
	total: 1,
	user: {},
};

export const selector = (state: RootState): State => state.user;
export const selectTotal = createSelector(selector, (state) => state.total);
export const selectUserMap = createSelector(selector, (state) => state.user);
export const selectUser = (id: User["id"]) => (state: RootState) => selectUserMap(state)[id];

export const setTotal = createAction("USER/TOTAL/SET")<number>();
export const setUser = createAction("USER/SET")<User>();
export const delUser = createAction("USER/DEL")<User["id"]>();

export type Action =
	| ReturnType<typeof setTotal>
	| ReturnType<typeof setUser>
	| ReturnType<typeof delUser>;
export const reducer = createReducer<State, Action>(initial, {
	"USER/TOTAL/SET": createSubReducer((state, action) => {
		const total = action.payload;
		state.total = total;
	}),
	"USER/SET": createSubReducer((state, action) => {
		const user = action.payload;
		state.user[user.id] = user;
	}),
	"USER/DEL": createSubReducer((state, action) => {
		const id = action.payload;
		delete state.user[id];
	}),
});

export function getUserList(offset: number, limit: number): AsyncThunkAction<{
	total: number;
	data: User[];
}> {
	return async (dispatch) => {
		const response = await api.getUserList(offset, limit);
		for (const user of response.data) {
			dispatch(setUser(user));
		}
		dispatch(setTotal(response.total));

		return response;
	};
}

export function getUser(id: number): AsyncThunkAction<User> {
	return async (dispatch) => {
		const user = await api.getUser(id);
		dispatch(setUser(user));

		return user;
	}
}

export function patchUser(id: number, body: Omit<User, "id" | "email">): AsyncThunkAction<User> {
	return async (dispatch) => {
		const user = await api.patchUser(id, body);
		dispatch(setUser(user));

		return user;
	};
}
