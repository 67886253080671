import React from 'react';

import {Link} from "react-router-dom";

import {useDispatch} from "../store";
import {deleteSession} from "../store/session";

import "./Header.css";

function Header() {
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = React.useState(false);
	const onLogoutClick = React.useCallback(async () => {
		await dispatch(deleteSession());
	}, []);
	const onBurgerClick = () => setIsOpen(!isOpen);

	const activeClass = isOpen ? "is-active" : "";

	return (
		<header className="container">
			<nav className="navbar is-mobile">
				<div className="navbar-brand">
					<div
						className={"navbar-burger " + activeClass}
						onClick={onBurgerClick}
					>
						<span aria-hidden="true" />
						<span aria-hidden="true" />
						<span aria-hidden="true" />
					</div>
				</div>
				<div className={"navbar-menu " + activeClass}>
					<div className="navbar-end">
						<Link to="/notice" className="navbar-item">
							공지사항
						</Link>
						<Link to="/lecture" className="navbar-item">
							강의
						</Link>
						<Link to="/user" className="navbar-item">
							사용자
						</Link>
						<Link to="/banner" className="navbar-item">
							배너
						</Link>
						<Link to="/my" className="navbar-item">
							마이페이지
						</Link>
						<div className="navbar-item">
							<div className="button is-small is-outlined" onClick={onLogoutClick}>
								로그아웃
							</div>
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
}

export default Header;
