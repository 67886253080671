import {createSelector} from "reselect";
import {createAction, createReducer} from "typesafe-actions";

import * as api from "../api";
import {Admin} from "../types";
import type {AsyncThunkAction, State as RootState} from "./index";
import {createSubReducer} from "./util";

export type State = {
	session?: Admin["id"];
};

const initial: State = {
	session: undefined,
};

export const selector = (state: RootState): State => state.session;
export const selectSession = createSelector(selector, (state) => state.session);

export const setSession = createAction("SESSION/SET")<Admin["id"]>();
export const delSession = createAction("SESSION/DEL")();

export type Action =
    | ReturnType<typeof setSession>
	| ReturnType<typeof delSession>;
export const reducer = createReducer<State, Action>(initial, {
	"SESSION/DEL": createSubReducer((state) => {
		state.session = undefined;
	}),
	"SESSION/SET": createSubReducer((state, action) => {
		const user = action.payload;
		state.session = user;
	}),
});

export function getSession(): AsyncThunkAction<Admin | undefined> {
	return async (dispatch) => {
		try {
			const admin = await api.getSession();
			dispatch(setSession(admin.id));
		} catch (e) {
            return undefined;
		}
	};
}

export function postSession(email: string, password: string): AsyncThunkAction<Admin> {
	return async (dispatch) => {
		const admin = await api.postSession(email, password);
		dispatch(setSession(admin.id));

		return admin;
	};
}

export function deleteSession(): AsyncThunkAction<void> {
	return async (dispatch) => {
		dispatch(deleteSession());
		await api.deleteSession();
	};
}
