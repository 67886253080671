import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {useLocation, Link} from "react-router-dom";

import Paginator from "../components/Paginator";
import {useAsyncEffect} from "../effect";
import {useDispatch, useSelector} from "../store";
import {deleteLecture, getLectureList, selectLectureMap, selectTotal} from "../store/lecture";
import './LectureList.css';

const LIMIT = 20;
function LectureList() {
	const dispatch = useDispatch();

	const query = new URLSearchParams(useLocation().search);
	const [isLoading, setIsLoading] = React.useState(false);
	const [idList, setIdList] = React.useState([] as number[]);

	const page = parseInt(query.get("page") ?? "1");
	const offset = (page - 1) * LIMIT;

	const total = useSelector(selectTotal);
	const lectureMap = useSelector(selectLectureMap);
	const lectureList = idList.map((id) => lectureMap[id]).filter((id) => id != null);

	const onDelete = React.useCallback(async (id: number) => {
		setIsLoading(true);
		try {
			await dispatch(deleteLecture(id));
			const response = await dispatch(getLectureList(offset, LIMIT));
			setIdList(response.data.map((notice) => notice.id));
		} catch (e) {
			// Do nothing
		}
		setIsLoading(false);
	}, []);

	useAsyncEffect(async () => {
		setIsLoading(true);
		try {
			const response = await dispatch(getLectureList(offset, LIMIT));
			setIdList(response.data.map((notice) => notice.id));
		} catch (e) {
			// Do nothing
		}
		setIsLoading(false);
	}, [offset]);

	let body: React.ReactNode;

	if (isLoading) {
		body = (
			<tr>
				<td colSpan={100}>
					<div className="loading">
						<FontAwesomeIcon icon={faSpinner} spin size="2x" />
					</div>
				</td>
			</tr>
		);
	} else {
		body = lectureList.map((lecture) => (
			<tr key={lecture.id}>
				<td className="lecture-id">{lecture.id}</td>
				<td className="lecture-title">{lecture.title}</td>
				<td className="lecture-button">
					<Link to={`/lecture/${lecture.id}`} className="button is-small">편집</Link>
				</td>
				<td className="lecture-button">
					<Link to={`/lecture/${lecture.id}/access`} className="button is-small">접속기록</Link>
				</td>
				<td className="lecture-button">
					<button className="button is-small" onClick={() => onDelete(lecture.id)}>삭제</button>
				</td>
				<td className="lecture-time">{dayjs.unix(lecture.timestamp).format("YYYY.MM.DD")}</td>
			</tr>
		));
	}

	return (
		<div className="lecture-list section">
			<div className="container">
				<div className="lecture-list-title">
					<h1 className="title">강의 목록</h1>
					<Link to="/lecture/new" className="button">추가</Link>
				</div>
				<table className="table is-striped">
					<thead>
						<tr>
							<th className="lecture-id">번호</th>
							<th className="lecture-title">제목</th>
							<th className="lecture-button"></th>
							<th className="lecture-button"></th>
							<th className="lecture-button"></th>
							<th className="lecture-time">등록일</th>
						</tr>
					</thead>
					<tbody>
						{body}
					</tbody>
				</table>
				<Paginator total={total} page={page} limit={LIMIT} href={(page) => `/lecture?page=${page}`} />
			</div>
		</div>
	);
}

export default LectureList;
