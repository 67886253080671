import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";

import {useAsyncEffect} from "../effect";
import {useDispatch, useSelector} from "../store";
import {getNotice, patchNotice, selectNotice} from "../store/notice";
import './Notice.css';

function Notice() {
	const dispatch = useDispatch();

	const params = useParams<{id: string}>();
	const id = parseInt(params.id);
	const notice = useSelector(selectNotice(id));

	const [isLoading, setIsLoading] = React.useState(true);
	const [isPatching, setIsPatching] = React.useState(false);
	const [title, setTitle] = React.useState("");
	const [permission, setPermission] = React.useState({
		0: true,
		1: false,
		2: false,
	});
	const [description, setDescription] = React.useState("");

	const onTitleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
	}, []);
	const onDescriptionChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setDescription(event.target.value);
	}, []);
	const onPermissionChange = [
		React.useCallback(() => setPermission({...permission, 0: !permission[0]}), [permission]),
		React.useCallback(() => setPermission({...permission, 1: !permission[1]}), [permission]),
		React.useCallback(() => setPermission({...permission, 2: !permission[2]}), [permission]),
	];
	const onConfirm = React.useCallback(async () => {
		if (isPatching) {
			return;
		}
		setIsPatching(true);
		await dispatch(patchNotice(id, {
			title,
			description,
			permission,
		}));
		setIsPatching(false);
	}, [isPatching, id, title, permission, description]);

	useAsyncEffect(async () => {
		if (notice == null) {
			setIsLoading(true);
			await dispatch(getNotice(id));
			return;
		}
		setIsLoading(false);
		if (notice != null) {
			setPermission({
				0: notice.permission[0],
				1: notice.permission[1],
				2: notice.permission[2],
			});
		}
	}, [id, notice])

	React.useEffect(() => {
		if (notice != null) {
			setTitle(notice.title);
			setDescription(notice.description);
			setPermission({
				0: notice.permission[0],
				1: notice.permission[1],
				2: notice.permission[2],
			});
		}
	}, [notice]);

	let body: React.ReactNode;
	if (isLoading) {
		body = (
			<div className="loading">
				<FontAwesomeIcon icon={faSpinner} spin size="2x"/>
			</div>
		)
	} else if (notice != null) {
		body = (
			<>
				<label>
					제목:
					<input value={title} onChange={onTitleChange} />
				</label>
				<div className="notice-view-permission">
					권한:
					<FontAwesomeIcon icon={permission[0] ? faCheckSquare : faSquare} onClick={onPermissionChange[0]} />0
					<FontAwesomeIcon icon={permission[1] ? faCheckSquare : faSquare} onClick={onPermissionChange[1]} />1
					<FontAwesomeIcon icon={permission[2] ? faCheckSquare : faSquare} onClick={onPermissionChange[2]} />2
				</div>
				<label className="notice-view-description">
					내용:
					<textarea value={description} onChange={onDescriptionChange} />
				</label>
			</>
		);
	} else {
		body = <span>존재하지 않는 공지입니다.</span>;
	}

	return (
		<div className="notice-view">
			<div className="section">
				<div className="container">
					<h1 className="title">공지 편집</h1>
					<div className="notice-view-body">
						{body}
					</div>
					<div className="notice-view-buttons">
						<button
							className={"button" + (isPatching ? " is-loading" : "")}
							onClick={onConfirm}
						>
							적용
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Notice;
