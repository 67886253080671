import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Switch, Redirect, Route} from "react-router-dom";

import Header from "./components/Header";
import {useAsyncEffect} from "./effect";
import Access from "./pages/Access";
import Banner from "./pages/Banner";
import Lecture from "./pages/Lecture";
import LectureList from "./pages/LectureList";
import LectureAdd from "./pages/LectureAdd";
import Notice from "./pages/Notice";
import NoticeAdd from "./pages/NoticeAdd";
import NoticeList from "./pages/NoticeList";
import SignIn from "./pages/SignIn";
import User from "./pages/User";
import UserList from "./pages/UserList";
import {useDispatch, useSelector} from "./store";
import {getSession, selectSession} from "./store/session";

import "./App.css";

function App() {
	const dispatch = useDispatch();

	const session = useSelector(selectSession);

	const [isLoading, setIsLoading] = React.useState(true);

	useAsyncEffect(async () => {
		setIsLoading(true);
		await dispatch(getSession());
		setIsLoading(false);
	}, []);

	if (isLoading) {
		return (
			<div className="root-loading">
				<FontAwesomeIcon icon={faSpinner} size="2x" spin />
			</div>
		);
	} else if (session != null) {
		return (
			<div className="root">
				<Header />
				<Switch>
					<Route path="/lecture" exact>
						<LectureList />
					</Route>
					<Route path="/lecture/new" exact>
						<LectureAdd />
					</Route>
					<Route path="/lecture/:id" exact>
						<Lecture />
					</Route>
					<Route path="/lecture/:id/access" exact>
						<Access />
					</Route>
					<Route path="/user" exact>
						<UserList />
					</Route>
					<Route path="/user/:id" exact>
						<User />
					</Route>
					<Route path="/notice" exact>
						<NoticeList />
					</Route>
					<Route path="/notice/new" exact>
						<NoticeAdd />
					</Route>
					<Route path="/notice/:id" exact>
						<Notice />
					</Route>
					<Route path="/banner" exact>
						<Banner />
					</Route>
					<Route>
						<Redirect to="/lecture" />
					</Route>
				</Switch>
			</div>
		);
	} else {
		return <SignIn />;
	}
}

export default App;
