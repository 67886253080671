import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";

import {useAsyncEffect} from "../effect";
import {useDispatch, useSelector} from "../store";
import {getUser, patchUser, selectUser} from "../store/user";
import './User.css';

function User() {
	const dispatch = useDispatch();

	const params = useParams<{id: string}>();
	const id = parseInt(params.id);
	const user = useSelector(selectUser(id));

	const [isLoading, setIsLoading] = React.useState(true);
	const [isPatching, setIsPatching] = React.useState(false);
	const [name, setName] = React.useState("");
	const [permission, setPermission] = React.useState({
		0: true,
		1: false,
		2: false,
	});

	const onNameChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	}, []);
	const onPermissionChange = [
		React.useCallback(() => setPermission({...permission, 0: !permission[0]}), [permission]),
		React.useCallback(() => setPermission({...permission, 1: !permission[1]}), [permission]),
		React.useCallback(() => setPermission({...permission, 2: !permission[2]}), [permission]),
	];
	const onConfirm = React.useCallback(async () => {
		if (isPatching) {
			return;
		}
		setIsPatching(true);
		await dispatch(patchUser(id, {
			name,
			permission,
		}));
		setIsPatching(false);
	}, [isPatching, id, name, permission]);

	useAsyncEffect(async () => {
		if (user == null) {
			setIsLoading(true);
			await dispatch(getUser(id));
		}
		setIsLoading(false);
		if (user != null) {
			setPermission({
				0: user.permission[0],
				1: user.permission[1],
				2: user.permission[2],
			});
		}
	}, [id, user])

	React.useEffect(() => {
		if (user != null) {
			setName(user.name);
			setPermission({
				0: user.permission[0],
				1: user.permission[1],
				2: user.permission[2],
			});
		}
	}, [user]);

	let body: React.ReactNode;
	if (isLoading) {
		body = (
			<div className="loading">
				<FontAwesomeIcon icon={faSpinner} spin size="2x"/>
			</div>
		)
	} else if (user != null) {
		body = (
			<>
				<label>
					이름:
					<input value={name} onChange={onNameChange} />
				</label>
				<div className="user-view-permission">
					권한:
					<FontAwesomeIcon icon={permission[0] ? faCheckSquare : faSquare} onClick={onPermissionChange[0]} />0
					<FontAwesomeIcon icon={permission[1] ? faCheckSquare : faSquare} onClick={onPermissionChange[1]} />1
					<FontAwesomeIcon icon={permission[2] ? faCheckSquare : faSquare} onClick={onPermissionChange[2]} />2
				</div>
			</>
		);
	} else {
		body = <span>존재하지 않는 공지입니다.</span>;
	}

	return (
		<div className="user-view">
			<div className="section">
				<div className="container">
					<h1 className="title">사용자 편집</h1>
					<div className="user-view-body">
						{body}
					</div>
					<div className="user-view-buttons">
						<button
							className={"button" + (isPatching ? " is-loading" : "")}
							onClick={onConfirm}
						>
							적용
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default User;
