import {createSelector} from "reselect";
import {createAction, createReducer} from "typesafe-actions";

import * as api from "../api";
import {Lecture} from "../types";
import type {AsyncThunkAction, State as RootState} from "./index";
import {createSubReducer} from "./util";

export type State = {
	total: number;
	lecture: Record<Lecture["id"], Lecture>;
};

const initial: State = {
	total: 1,
	lecture: {},
};

export const selector = (state: RootState): State => state.lecture;
export const selectTotal = createSelector(selector, (state) => state.total);
export const selectLectureMap = createSelector(selector, (state) => state.lecture);
export const selectLecture = (id: Lecture["id"]) => (state: RootState) => selectLectureMap(state)[id];

export const setTotal = createAction("LECTURE/TOTAL/SET")<number>();
export const delLecture = createAction("LECTURE/DEL")<Lecture["id"]>();
export const setLecture = createAction("LECTURE/SET")<Lecture>();

export type Action =
	| ReturnType<typeof setTotal>
	| ReturnType<typeof delLecture>
	| ReturnType<typeof setLecture>;
export const reducer = createReducer<State, Action>(initial, {
	"LECTURE/TOTAL/SET": createSubReducer((state, action) => {
		const total = action.payload;
		state.total = total;
	}),
	"LECTURE/DEL": createSubReducer((state, action) => {
		const id = action.payload;
		delete state.lecture[id];
	}),
	"LECTURE/SET": createSubReducer((state, action) => {
		const lecture = action.payload;
		state.lecture[lecture.id] = lecture;
	}),
});

export function getLectureList(offset: number, limit: number): AsyncThunkAction<{
	total: number;
	data: Lecture[];
}> {
	return async (dispatch) => {
		const response = await api.getLectureList(offset, limit);
		for (const notice of response.data) {
			dispatch(setLecture(notice));
		}
		dispatch(setTotal(response.total));

		return response;
	};
}

export function getLecture(id: number): AsyncThunkAction<Lecture> {
	return async (dispatch) => {
		const lecture = await api.getLecture(id);
		dispatch(setLecture(lecture));

		return lecture;
	};
}

export function patchLecture(id: number, body: Omit<Lecture, "id" | "timestamp">): AsyncThunkAction<Lecture> {
	return async (dispatch) => {
		const lecture = await api.patchLecture(id, body);
		dispatch(setLecture(lecture));

		return lecture;
	};
}

export function postLecture(body: Omit<Lecture, "id" | "timestamp">): AsyncThunkAction<Lecture> {
	return async (dispatch) => {
		const lecture = await api.postLecture(body);
		dispatch(setLecture(lecture));

		return lecture;
	};
}

export function deleteLecture(id: Lecture["id"]): AsyncThunkAction<void> {
	return async (dispatch) => {
		await api.deleteLecture(id);
		dispatch(delLecture(id));
	}
}
