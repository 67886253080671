import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";
import {useHistory} from "react-router-dom";

import {useDispatch} from "../store";
import {postNotice} from "../store/notice";
import './NoticeAdd.css';

function NoticeAdd() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [isPosting, setIsPosting] = React.useState(false);
	const [title, setTitle] = React.useState("");
	const [permission, setPermission] = React.useState({
		0: true,
		1: false,
		2: false,
	});
	const [description, setDescription] = React.useState("");
	const [error, setError] = React.useState("");

	const onTitleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
	}, []);
	const onDescriptionChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setDescription(event.target.value);
	}, []);
	const onPermissionChange = [
		React.useCallback(() => setPermission({...permission, 0: !permission[0]}), [permission]),
		React.useCallback(() => setPermission({...permission, 1: !permission[1]}), [permission]),
		React.useCallback(() => setPermission({...permission, 2: !permission[2]}), [permission]),
	];
	const onConfirm = React.useCallback(async () => {
		if (isPosting) {
			return;
		}
		setIsPosting(true);
		try {
			await dispatch(postNotice({
				title,
				description,
				permission,
			}));
			setIsPosting(false);
			history.push("/notice");
		} catch (e) {
			setError("알 수 없는 오류가 발생했습니다.");
			setIsPosting(false);
		}
	}, [isPosting, history, title, permission, description]);

	return (
		<div className="notice-add">
			<div className="section">
				<div className="container">
					<h1 className="title">공지 추가</h1>
					<div className="notice-add-body">
						<label>
							제목:
							<input value={title} onChange={onTitleChange} />
						</label>
						<div className="notice-add-permission">
							권한:
							<FontAwesomeIcon icon={permission[0] ? faCheckSquare : faSquare} onClick={onPermissionChange[0]} />0
							<FontAwesomeIcon icon={permission[1] ? faCheckSquare : faSquare} onClick={onPermissionChange[1]} />1
							<FontAwesomeIcon icon={permission[2] ? faCheckSquare : faSquare} onClick={onPermissionChange[2]} />2
						</div>
						<label className="notice-add-description">
							내용:
							<textarea value={description} onChange={onDescriptionChange} />
						</label>
					</div>
					<div className="notice-add-error">
						<span>{error}</span>
					</div>
					<div className="notice-add-buttons">
						<button
							className={"button" + (isPosting ? " is-loading" : "")}
							onClick={onConfirm}
						>
							생성
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NoticeAdd;
