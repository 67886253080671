import React from 'react';

import {HttpError} from "../api";
import {useDispatch} from "../store";
import {postSession} from "../store/session";

import "./SignIn.css";

function App() {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [error, setError] = React.useState("");

	const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsLoading(true);
		try {
			await dispatch(postSession(email, password));
		} catch (e) {
			if (e instanceof HttpError) {
				switch (e.code) {
					case 401: setError("비밀번호가 잘못되었습니다"); break;
					case 404: setError("존재하지 않는 아이디입니다"); break;
					default: setError("알 수 없는 오류가 발생했습니다"); break;
				}
			}
		}
		setIsLoading(false);
	}, [email, password]);
	const onEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	}, []);
	const onPasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	}, []);

	return (
		<div className="signin">
			<div className="wrapper">
				<h1 className="title">관리 페이지</h1>
				<form onSubmit={onSubmit}>
					<div className="field">
						<label className="label">이메일</label>
						<div className="control">
							<input
								className="input"
								type="email"
								placeholder="example@gmail.com"
								value={email}
								onChange={onEmailChange}
							/>
						</div>
					</div>
					<div className="field">
						<label className="label">비밀번호</label>
						<div className="control">
							<input
								className="input"
								type="password"
								value={password}
								onChange={onPasswordChange}
							/>
						</div>
					</div>
					<div className="signin-error">
						<span>{error}</span>
					</div>
					<div className="field is-grouped is-grouped-centered mt-6">
						<p className="control">
							{
								!isLoading ?
								<button className="button">로그인</button> :
								<button className="button is-loading" />
							}
						</p>
					</div>
				</form>
			</div>
		</div>
	);
}

export default App;
