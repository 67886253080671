import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {useLocation, useParams, Link} from "react-router-dom";

import Paginator from "../components/Paginator";
import {useAsyncEffect} from "../effect";
import {useDispatch, useSelector} from "../store";
import {getAccessList, selectAccessMap, selectTotal} from "../store/access";
import {getLecture, selectLecture} from "../store/lecture";
import {selectUserMap} from "../store/user";
import './Access.css';

const LIMIT = 20;
function Access() {
	const dispatch = useDispatch();

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const params = useParams<{id: string}>();

	const [isLoading, setIsLoading] = React.useState(false);
	const [idList, setIdList] = React.useState([] as number[]);

	const lectureId = parseInt(params.id);
	const page = parseInt(query.get("page") ?? "1");
	const offset = (page - 1) * LIMIT;

	const lecture = useSelector(selectLecture(lectureId));
	const total = useSelector(selectTotal(lectureId));
	const accessMap = useSelector(selectAccessMap);
	const accessList = idList.map((id) => accessMap[id]).filter((id) => id != null);
	const userMap = useSelector(selectUserMap);

	useAsyncEffect(async () => {
		setIsLoading(true);
		try {
			const response = await dispatch(getAccessList(lectureId, offset, LIMIT));
			setIdList(response.map((access) => access.id));
		} catch (e) {
			setIdList([]);
		}
		setIsLoading(false);
	}, [lectureId, offset]);

	let body: React.ReactNode;

	if (isLoading) {
		body = (
			<tr>
				<td colSpan={100}>
					<div className="loading">
						<FontAwesomeIcon icon={faSpinner} spin size="2x" />
					</div>
				</td>
			</tr>
		);
	} else {
		body = accessList.map((access) => {
			const user = userMap[access.user];

			return (
				<tr key={access.id}>
					<td className="access-id">{access.id}</td>
					<td className="access-user-email">{user.email}</td>
					<td className="access-user-name">{user.name}</td>
					<td className="access-time">{dayjs.unix(access.timestamp).format("YYYY.MM.DD HH:mm")}</td>
				</tr>
			)
		});
	}

	return (
		<div className="access-list section">
			<div className="container">
				<div className="access-list-title">
					<h1 className="title">접근 기록</h1>
				</div>
				<table className="table is-striped">
					<thead>
						<tr>
							<th className="access-id">번호</th>
							<th className="access-user-email">사용자 이메일</th>
							<th className="access-user-name">사용자 이름</th>
							<th className="access-time">접속 시간</th>
						</tr>
					</thead>
					<tbody>
						{body}
					</tbody>
				</table>
				<Paginator total={total} page={page} limit={LIMIT} href={(page) => `/lecture/${lectureId}/access?page=${page}`} />
			</div>
		</div>
	);
}

export default Access;
