import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

import "./Paginator.css";

type Props = {
	total: number;
	page: number;
	limit: number;
	href: (page: number) => string;
};

function Paginator(props: Props) {
	const {total, page, limit, href} = props;

	const lastPage = Math.ceil(total / limit);
	const groupStart = Math.floor((page - 1) / 10) + 1;
	const groupEnd = Math.min(groupStart + 9, lastPage);
	const hasPrev = page > 10;
	const hasNext = Math.floor((page - 1) / 10) < Math.floor((lastPage - 1) / 10);
	const pages: number[] = [];
	for (let i = groupStart; i <= groupEnd; ++i) {
		pages.push(i);
	}

	return (
		<nav className="custom-paginator">
			<Link to={href(1)} className="custom-paginator-first">
				<FontAwesomeIcon icon={faAngleDoubleLeft} />
			</Link>
			{hasPrev ? (
				<Link to={href(groupStart - 1)} className="custom-paginator-prev">
					<FontAwesomeIcon icon={faAngleLeft} />
				</Link>
			) : null}
			{pages.map((p) => (
				<li key={p}>
					<Link to={href(p)} className={"custom-paginator-item" + (p === page ? " active" : "")}>
						{p.toString()}
					</Link>
				</li>
			))}
			{hasNext ? (
				<Link to={href(groupEnd + 1)} className="custom-paginator-next">
					<FontAwesomeIcon icon={faAngleRight} />
				</Link>
			) : null}
			<Link to={href(lastPage)} className="custom-paginator-last">
				<FontAwesomeIcon icon={faAngleDoubleRight} />
			</Link>
		</nav>
	);
}

export default Paginator;
