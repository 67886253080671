import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useLocation, Link} from "react-router-dom";

import Paginator from "../components/Paginator";
import {useAsyncEffect} from "../effect";
import {useDispatch, useSelector} from "../store";
import {getUserList, selectUserMap, selectTotal} from "../store/user";
import './UserList.css';

const LIMIT = 20;
function UserList() {
	const dispatch = useDispatch();

	const query = new URLSearchParams(useLocation().search);
	const [isLoading, setIsLoading] = React.useState(false);
	const [idList, setIdList] = React.useState([] as number[]);

	const page = parseInt(query.get("page") ?? "1");
	const offset = (page - 1) * LIMIT;

	const total = useSelector(selectTotal);
	const userMap = useSelector(selectUserMap);
	const userList = idList.map((id) => userMap[id]).filter((id) => id != null);

	useAsyncEffect(async () => {
		setIsLoading(true);
		try {
			const response = await dispatch(getUserList(offset, LIMIT));
			setIdList(response.data.map((user) => user.id));
		} catch (e) {
			// Do nothing
		}
		setIsLoading(false);
	}, [offset]);

	let body: React.ReactNode;

	if (isLoading) {
		body = (
			<tr>
				<td colSpan={100}>
					<div className="loading">
						<FontAwesomeIcon icon={faSpinner} spin size="2x" />
					</div>
				</td>
			</tr>
		);
	} else {
		body = userList.map((user) => (
			<tr key={user.id}>
				<td className="user-id">{user.id}</td>
				<td className="user-name">{user.name}</td>
				<td className="user-email">{user.email}</td>
				<td className="user-button">
					<Link to={`/user/${user.id}`} className="button is-small">편집</Link>
				</td>
			</tr>
		));
	}

	return (
		<div className="user-list section">
			<div className="container">
				<div className="user-list-title">
					<h1 className="title">사용자 목록</h1>
				</div>
				<table className="table is-striped">
					<thead>
						<tr>
							<th className="user-id">번호</th>
							<th className="user-name">이름</th>
							<th className="user-email">이메일</th>
							<th className="user-button"></th>
						</tr>
					</thead>
					<tbody>
						{body}
					</tbody>
				</table>
				<Paginator total={total} page={page} limit={LIMIT} href={(page) => `/user?page=${page}`} />
			</div>
		</div>
	);
}

export default UserList;
