import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {useAsyncEffect} from "../effect";
import {useDispatch} from "../store";
import {getLecture, patchLecture, selectLecture} from "../store/lecture";
import './Lecture.css';

function Lecture() {
	const dispatch = useDispatch();

	const params = useParams<{id: string}>();
	const id = parseInt(params.id);
	const lecture = useSelector(selectLecture(id));

	const [isLoading, setIsLoading] = React.useState(true);
	const [isPatching, setIsPatching] = React.useState(false);
	const [title, setTitle] = React.useState("");
	const [media, setMedia] = React.useState("");
	const [permission, setPermission] = React.useState({
		0: true,
		1: false,
		2: false,
	});
	const [description, setDescription] = React.useState("");

	const onTitleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
	}, []);
	const onMediaChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setMedia(event.target.value);
	}, []);
	const onDescriptionChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setDescription(event.target.value);
	}, []);
	const onPermissionChange = [
		React.useCallback(() => setPermission({...permission, 0: !permission[0]}), [permission]),
		React.useCallback(() => setPermission({...permission, 1: !permission[1]}), [permission]),
		React.useCallback(() => setPermission({...permission, 2: !permission[2]}), [permission]),
	];
	const onConfirm = React.useCallback(async () => {
		if (isPatching) {
			return;
		}
		setIsPatching(true);
		await dispatch(patchLecture(id, {
			title,
			media: media !== "" ? media : undefined,
			description,
			permission,
		}));
		setIsPatching(false);
	}, [isPatching, id, title, media, permission, description]);

	useAsyncEffect(async () => {
		if (lecture == null) {
			setIsLoading(true);
			await dispatch(getLecture(id));
		}
		setIsLoading(false);
	}, [id, lecture])

	React.useEffect(() => {
		if (lecture != null) {
			setTitle(lecture.title);
			setDescription(lecture.description);
			setMedia(lecture.media || "");
			setPermission({
				0: lecture.permission[0],
				1: lecture.permission[1],
				2: lecture.permission[2],
			});
		}
	}, [lecture]);

	let body: React.ReactNode;
	if (isLoading) {
		body = (
			<div className="loading">
				<FontAwesomeIcon icon={faSpinner} spin size="2x"/>
			</div>
		)
	} else if (lecture != null) {
		body = (
			<>
				<label>
					제목:
					<input value={title} onChange={onTitleChange} />
				</label>
				<label>
					유튜브 ID:
					<input value={media} onChange={onMediaChange} />
				</label>
				<div className="lecture-view-permission">
					권한:
					<FontAwesomeIcon icon={permission[0] ? faCheckSquare : faSquare} onClick={onPermissionChange[0]} />0
					<FontAwesomeIcon icon={permission[1] ? faCheckSquare : faSquare} onClick={onPermissionChange[1]} />1
					<FontAwesomeIcon icon={permission[2] ? faCheckSquare : faSquare} onClick={onPermissionChange[2]} />2
				</div>
				<label className="lecture-view-description">
					내용:
					<textarea value={description} onChange={onDescriptionChange} />
				</label>
			</>
		);
	} else {
		body = <span>존재하지 않는 강의입니다.</span>;
	}

	return (
		<div className="lecture-view">
			<div className="section">
				<div className="container">
					<h1 className="title">강의 편집</h1>
					<div className="lecture-view-body">
						{body}
					</div>
					<div className="lecture-view-buttons">
						<button
							className={"button" + (isPatching ? " is-loading" : "")}
							onClick={onConfirm}
						>
							적용
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Lecture;
